// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-expertise-tsx": () => import("./../../../src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-projects-b-2-connect-tsx": () => import("./../../../src/pages/projects/b2-connect.tsx" /* webpackChunkName: "component---src-pages-projects-b-2-connect-tsx" */),
  "component---src-pages-projects-backlite-media-tsx": () => import("./../../../src/pages/projects/backlite-media.tsx" /* webpackChunkName: "component---src-pages-projects-backlite-media-tsx" */),
  "component---src-pages-projects-brand-affinity-tsx": () => import("./../../../src/pages/projects/brand-affinity.tsx" /* webpackChunkName: "component---src-pages-projects-brand-affinity-tsx" */),
  "component---src-pages-projects-convidi-tsx": () => import("./../../../src/pages/projects/convidi.tsx" /* webpackChunkName: "component---src-pages-projects-convidi-tsx" */),
  "component---src-pages-projects-loyalty-manager-tsx": () => import("./../../../src/pages/projects/loyalty-manager.tsx" /* webpackChunkName: "component---src-pages-projects-loyalty-manager-tsx" */),
  "component---src-pages-projects-maxima-telecom-tsx": () => import("./../../../src/pages/projects/maxima-telecom.tsx" /* webpackChunkName: "component---src-pages-projects-maxima-telecom-tsx" */),
  "component---src-pages-projects-mosaic-tsx": () => import("./../../../src/pages/projects/mosaic.tsx" /* webpackChunkName: "component---src-pages-projects-mosaic-tsx" */),
  "component---src-pages-projects-movie-teller-tsx": () => import("./../../../src/pages/projects/movie-teller.tsx" /* webpackChunkName: "component---src-pages-projects-movie-teller-tsx" */),
  "component---src-pages-projects-nexus-mentis-tsx": () => import("./../../../src/pages/projects/nexus-mentis.tsx" /* webpackChunkName: "component---src-pages-projects-nexus-mentis-tsx" */),
  "component---src-pages-projects-pubmatic-tsx": () => import("./../../../src/pages/projects/pubmatic.tsx" /* webpackChunkName: "component---src-pages-projects-pubmatic-tsx" */),
  "component---src-pages-projects-sportex-tsx": () => import("./../../../src/pages/projects/sportex.tsx" /* webpackChunkName: "component---src-pages-projects-sportex-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-projects-video-sift-tsx": () => import("./../../../src/pages/projects/video-sift.tsx" /* webpackChunkName: "component---src-pages-projects-video-sift-tsx" */),
  "component---src-pages-projects-your-stylist-tsx": () => import("./../../../src/pages/projects/your-stylist.tsx" /* webpackChunkName: "component---src-pages-projects-your-stylist-tsx" */),
  "component---src-pages-services-cloud-tsx": () => import("./../../../src/pages/services/cloud.tsx" /* webpackChunkName: "component---src-pages-services-cloud-tsx" */),
  "component---src-pages-services-consulting-tsx": () => import("./../../../src/pages/services/consulting.tsx" /* webpackChunkName: "component---src-pages-services-consulting-tsx" */),
  "component---src-pages-services-data-science-tsx": () => import("./../../../src/pages/services/data-science.tsx" /* webpackChunkName: "component---src-pages-services-data-science-tsx" */),
  "component---src-pages-services-development-tsx": () => import("./../../../src/pages/services/development.tsx" /* webpackChunkName: "component---src-pages-services-development-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/blog-post/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-plain-text-page-plain-text-page-tsx": () => import("./../../../src/templates/plain-text-page/plain-text-page.tsx" /* webpackChunkName: "component---src-templates-plain-text-page-plain-text-page-tsx" */)
}

