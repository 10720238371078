module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mediaBefore320":"(max-width: 320px)","mediaBefore475":"(max-width: 475px)","mediaBefore600":"(max-width: 600px)","mediaBefore800":"(max-width: 800px)","mediaBefore1280":"(max-width: 1280px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"gtagConfig":{"optimize_id":"GTM-NN97X6GD","anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true},"trackingIds":["UA-171565631-1","G-9XXYJ05MLJ","G-5846L8XMEJ","GT-PLFD8WH"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NN97X6GD","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Inventale Outsource","short_name":"Inventale","start_url":"/","background_color":"#ffffff","theme_color":"#F72C25","display":"minimal-ui","icon":"src/assets/images/logo-square.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9a045788047a07e6e8857b55689b47b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/dev/outsource-site/static/locales","languages":["en","ar"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"2428792","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"f0bc91b2a278813958b24567d3591554","enableOnDevMode":false,"pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"publicPath":"admin","htmlTitle":"Content Manager","includeRobots":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
